import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getVehicleVariants, TranslatedVariant } from '@api/endpoints/translations'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetVariants } from './queryKeys'

export const useQueryGetVehicleVariants = (
  opts: {
    enabled?: boolean
  } = {}
): UseQueryResult<ReadonlyArray<TranslatedVariant>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetVariants,
    queryFn: getVehicleVariants,
    ...opts
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
